::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f9f9f9; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #1b1617; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #1b1617; 
  }

.bg-light-grey {
    background: $bg-light-grey;
}

// .bg-light-yellow {
//   background: $bg-light-yellow;
// }


.theme-color {
    // background-color: #1b1617;
    // background-color: #d0d1d3;
    background-color: #f3f3f3;
}

.text-white h2 {
    color: $white !important;
}

.text-white h2::before {
    background-color: $white !important;
}

.text-white h2::after {
    background-color: $white !important;
}

.product-content {
    &.title-price-wrap-2-white {
        h3 {
            a {
                color: #fff;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        .product-price {
            span {
                color: #b3b3b3;
                &::before {
                    background-color: #b3b3b3;
                }
                &.old {
                    text-decoration: line-through;
                    color: #797979;
                }
            }
        }
    }
}

// ------------ Top Bar -----------------
.header-offer p {
    line-height: 54px;
}

// --------------------------------------
// ----------- About Us Page ------------
.welcome-content p {
    width: 100%;
    text-align: left;
}

// --------------------------------------
// -------------- Header ----------------
.logo {
    height: 100%;
    // margin-top: 0px;
    &>a {
        display: block;
        height: 100%;
        text-align: center;
        img {
            //   margin-top: 14px;
            width: 100%;
        }
    }
}

.stick {
    & .logo>a>img {
        // margin-top: 0px;
        // width: 70%;
    }
}

// -----------------------------------------
// ------------- Instagram Wall ------------
.Feed img {
    width: 12.5%;
}

.Feed {
    padding-top: 50px;
}

// -----------------------------------------
// ----------------- Footer ----------------
.footer-logo {
    img {
        width: 100%;
    }
}

// -----------------------------------------
// --------- Listing page filters ----------
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
    top: 5px;
}

// -----------------------------------------
// ------------ Product Gallery ------------
.product-large-image-wrapper .react_lightgallery_item button {
    position: absolute;
}

//
.btn-secondary p {
    color: #fff !important;
}

.billing-info-wrap .btn-group {
    display: inherit !important;
}

// -----------------------------------------
// --------- Products Details Page ---------
.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark:after {
    left: -3px;
    top: -6px;
}

// -----------------------------------------
// ----- Checkout Address Card Checkboxes -----
.address-card-lable {
    width: 100%;
}

.card-input-element {
    display: none;
}

.card-input {
    margin: 10px;
    padding: 00px;
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked+.card-input {
    box-shadow: 0 0 1px 1px #2ecc71;
}

#add-address-card {
    text-align: center;
}

// -----------------------------------------------
input#checkout-different-address {
    width: 14%;
    float: left;
    margin-right: 10px;
}

// ------------- Menu START -----------------
.main-menu nav ul li:hover a {
    // color: #fff;
    color: #5f5e5f;
}

// _______Bikes Categories______
.bike-categories-menu-img-container {
    width: 300px;
    height: 258px;
    overflow: hidden;
}

.mega-menu-img img {
    width: 100%;
}

ul.bike-categories-menu {
    width: 540px !important;
}

.bike-categories-menu>li:nth-child(1) {
    width: 35% !important;
}

.bike-categories-menu>li:nth-child(2) {
    width: 65% !important;
}

// _____Bikes Categories END_____
// _____Spares & Accessories_____
.sub-menu-accessories {
    min-width: 436px;
    li {
        width: 50%;
        display: inline-block !important;
        float: left !important;
    }
}

.main-menu nav ul li ul.mega-menu.spares-accessories-megamenu {
    width: 753px;
    left: -156%;
}

.spares-column {
    width: 40% !important;
}

.accessories-column {
    width: 60% !important;
}

// _____Spares & Accessories END_____
// _____Shop By Brands_____
.shop-by-brands-sub-menu {
    min-width: 330px;
    li {
        width: 50%;
        display: inline-block !important;
        float: left !important;
    }
}

.shop-by-brands {
    width: 100% !important;
}

.shop-by-brands-mega-menu {
    width: auto !important;
}

// _____Shop By Brands END_____
// ------------------------------------------------------
.your-order-total {
    width: 100%;
}

.welcome-area.pt-100.pb-95 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.welcome-content p {
    font-size: 15px;
    color: #4b4b4b;
    line-height: 30px;
    width: 100%;
    margin: 0 auto;
}

.contact-info-wrap {
    padding: 120px 10px 116px 10px;
    background-color: #f3f3f3;
}

@media only screen and (min-width: 1245px) and (max-width: 1395px) {
    .main-menu nav ul li {
        padding: 0 12px;
    }
}

@media only screen and (min-width: 1161px) and (max-width: 1244px) {
    .main-menu nav ul li {
        padding: 0 10px;
    }
    .logo {
        height: 100% !important;
        margin-top: 28px !important;
    }
    .main-menu nav ul li>a {
        font-size: 14px;
        font-weight: 500;
        line-height: 94px;
        display: inline-block;
        letter-spacing: 0.8px;
        color: #5f5e5f;
    }
    .header-right-wrap .same-style>a,
    .header-right-wrap .same-style button {
        font-size: 18px;
        color: #000;
    }
    .header-right-wrap .same-style.account-setting>a,
    .header-right-wrap .same-style.account-setting button {
        font-size: 20px;
    }
    .header-right-wrap .same-style.cart-wrap button,
    .header-right-wrap .same-style.cart-wrap>a,
    .header-right-wrap .same-style.header-wishlist button,
    .header-right-wrap .same-style.header-wishlist>a,
    .header-right-wrap .same-style.header-compare button,
    .header-right-wrap .same-style.header-compare>a {
        font-size: 20px;
        position: relative;
        display: inline-block;
        padding: 0;
        color: #000;
        border: none;
        background: transparent;
    }
}

@media only screen and (min-width: 1070px) and (max-width: 1160px) {
    .main-menu nav ul li {
        padding: 0 10px;
    }
    .logo {
        height: 100% !important;
        margin-top: 28px !important;
    }
    .main-menu nav ul li>a {
        font-size: 12px;
        font-weight: 500;
        line-height: 94px;
        display: inline-block;
        letter-spacing: 0.8px;
        color: #5f5e5f;
    }
    .header-right-wrap .same-style>a,
    .header-right-wrap .same-style button {
        font-size: 18px;
        color: #000;
    }
    .header-right-wrap .same-style.account-setting>a,
    .header-right-wrap .same-style.account-setting button {
        font-size: 20px;
    }
    .header-right-wrap .same-style.cart-wrap button,
    .header-right-wrap .same-style.cart-wrap>a,
    .header-right-wrap .same-style.header-wishlist button,
    .header-right-wrap .same-style.header-wishlist>a,
    .header-right-wrap .same-style.header-compare button,
    .header-right-wrap .same-style.header-compare>a {
        font-size: 20px;
        position: relative;
        display: inline-block;
        padding: 0;
        color: #000;
        border: none;
        background: transparent;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1069px) {
    .main-menu nav ul li {
        padding: 0 10px;
    }
    .logo {
        height: 100% !important;
        margin-top: 28px !important;
    }
    .main-menu nav ul li>a {
        font-size: 11px;
        font-weight: 500;
        line-height: 94px;
        display: inline-block;
        letter-spacing: 0.8px;
        color: #5f5e5f;
    }
    .header-right-wrap .same-style>a,
    .header-right-wrap .same-style button {
        font-size: 18px;
        color: #000;
    }
    .header-right-wrap .same-style.account-setting>a,
    .header-right-wrap .same-style.account-setting button {
        font-size: 18px;
    }
    .header-right-wrap .same-style.cart-wrap button,
    .header-right-wrap .same-style.cart-wrap>a,
    .header-right-wrap .same-style.header-wishlist button,
    .header-right-wrap .same-style.header-wishlist>a,
    .header-right-wrap .same-style.header-compare button,
    .header-right-wrap .same-style.header-compare>a {
        font-size: 18px;
        position: relative;
        display: inline-block;
        padding: 0;
        color: #000;
        border: none;
        background: transparent;
    }
    .header-right-wrap .same-style {
        margin-left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-right-wrap {
        // margin-top: 35px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-padding-1 .container-fluid,
    .header-padding-1 .container-sm,
    .header-padding-1 .container-md,
    .header-padding-1 .container-lg,
    .header-padding-1 .container-xl {
        padding: 0 40px !important;
    }
}

@media only screen and (max-width: 767px) {
    .header-padding-1 .container-fluid,
    .header-padding-1 .container-sm,
    .header-padding-1 .container-md,
    .header-padding-1 .container-lg,
    .header-padding-1 .container-xl {
        padding: 0 35px !important;
    }
}

@media only screen and (max-width: 767px) {
    .header-right-wrap {
        margin-top: 15px;
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .header-res-padding {
        padding: 0px 0 0px;
    }
    .bg-light-yellow {
        padding: 0 0px 0 0px;
    }
    .logo>a {
        display: block;
        height: 100%;
        // text-align: center;
        line-height: 44px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
    .footer-widget.ml-30,
    .footer-widget.ml-50,
    .footer-widget.ml-75 {
        margin-left: 0;
        text-align: center;
    }
    .copyright.mb-30 p {
        text-align: center;
    }
    .footer-widget.ml-70 {
        margin-left: 0;
        text-align: center;
    }
    .copyright .footer-logo {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .stick .logo,
    .stick .header-right-wrap {
        margin-top: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-height-1 {
        padding: 0 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .slider-height-1 {
        height: auto;
        padding: 0px 0 0;
    }
    .brand-logo {
        margin-bottom: 10px;
    }
    .banner-area .container-fluid {
        padding: 0px 15px !important;
    }
    .single-banner {
        margin: 0px !important;
    }
    .single-banner a img {
        margin-bottom: 15px;
    }
    .featuretitle {
        font-size: 25px;
    }
    .footer-logo img {
        width: auto;
    }
    .footer-logo {
        text-align: center;
    }
}

.single-slider .container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding: 0px;
}

.static-container h6 {
    text-align: center;
}

.contact-info-wrap .single-contact-info .contact-icon {
    margin-right: 7px !important;
}

@media only screen and (max-width: 998px) {
    .contact-info-wrap .single-contact-info .contact-info-dec p {
        font-size: 13px;
    }
}

h2.pop {
    font-size: 11px !important;
    line-height: 20px !important;
}

.pop::before,
.pop::after {
    top: 9px !important;
}

.blinking {
    animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
    0% {
        color: rgb(255, 6, 6);
    }
    49% {
        color: rgb(248, 17, 17);
    }
    60% {
        color: #212529;
    }
    99% {
        color: #212529;
    }
    100% {
        color: rgb(247, 17, 17);
    }
}

.section-title.text-center.sxs {
    background-color: #fdfdfd;
}

// .main-menu nav li:hover {
//   background-color: #928c8c61;
// }
ul.submenu li:hover {
    background-color: white;
}

ul.mega-menu.mega-menu-padding.shop-by-brands-mega-menu li:hover {
    background-color: #ffffff;
}

ul.mega-menu.bike-categories-menu li:hover {
    background-color: #ffffff;
}

ul.mega-menu.mega-menu-padding.spares-accessories-megamenu li:hover {
    background-color: white;
}

.footer-widget .footer-list ul li a:hover {
    color: #fff !important;
}

.copyright p a:hover {
    color: #fff !important;
    font-weight: 400;
}

.footer-widget .subscribe-style .subscribe-form .clear button {
    font-size: 14px;
    line-height: 1;
    width: auto;
    height: auto;
    margin: 13px 0 0;
    padding: 0 0 1px;
    text-transform: uppercase;
    color: #040303;
    border: none;
    background: #fff;
    padding: 8px;
    font-weight: 700;
}

.product-tab-list {
    justify-content: center;
}

.header-hm9 .main-menu nav ul li a {
    color: #5f5e5f;
}

.main-menu nav ul li ul.mega-menu>li ul li a:hover {
    color: #5f5e5f;
}

.welcome-content p {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
    width: 66%;
    margin: 0 auto;
    text-align: center;
}

.product-wrap-2 .product-content-2 {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
    text-align: center;
}

.custom2-col-4 {
    position: relative;
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
    min-height: 1px;
}

.product-wrap-2 {
    position: relative;
    overflow: hidden;
    padding: 0 0 40px 0;
    border-bottom: 3px solid #fff;
}

.product-wrap-2:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    transition: 0.3s ease-in-out;
    transform: translateY(-5px);
    border-bottom: 3px solid #5f5e5f;
}

.home1footer {
    background-color: #2a2a2a;
}

.footer-widget .subscribe-style .subscribe-form .clear button:hover {
    background-color: #fff;
    color: #000;
    border-bottom: none;
    transform: translateY(2px);
}

.nice-select {
    border-radius: 0;
    color: #65696c;
    max-width: 100%;
}

.nice-select .current {
    font-size: 18px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
}

.nice-select:hover {
    border-color: #ffd910;
}

.nice-select .list {
    border-radius: 0;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: #ffd910;
}

.nice-select .option.disabled {
    color: #535b65;
}


.s-find-bike {
    margin-top: -54px;
    position: relative;
    z-index: 3;
}

.s-find-bike .title {
    margin-bottom: 29px;
    text-align: center;
}

.s-find-bike .title-cover {
    text-align: center;
}

@media (max-width: 1366px) {
    .s-find-bike {
        margin-top: -48px;
    }
}

@media (max-width: 1199px) {
    .s-find-bike {
        margin-top: -35px;
    }
    .find-bike-form {
        padding: 44px 30px 48px;
    }
}

@media (max-width: 991px) {
    .s-find-bike .nice-select {
        padding-left: 15px;
        padding-right: 24px;
    }
    .s-find-bike .nice-select:after {
        right: 10px;
    }
    .find-bike-form .form-wrap>li .btn {
        min-width: 150px;
    }
    .find-bike-form .form-wrap>li .nice-select {
        max-width: 146px;
    }
    .nice-select .option {
        padding-left: 15px;
        padding-right: 10px;
        font-size: 12px;
        line-height: 36px;
        min-height: 36px;
    }
}

@media (max-width: 767px) {
    .s-find-bike .find-bike-form .form-wrap>li {
        margin-right: 0;
        margin-bottom: 20px;
        width: calc(50% - 15px);
    }
    .s-find-bike {
        margin-top: 34px;
    }
    .s-find-bike .title {
        margin-bottom: 24px;
    }
    .find-bike-form .form-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .find-bike-form {
        padding: 39px 30px 20px;
    }
    .find-bike-form .form-wrap>li .nice-select {
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .s-find-bike .find-bike-form .form-wrap>li {
        width: 100%;
    }
    .find-bike-form .form-wrap>li:last-child {
        margin-top: 10px;
    }.nice-select .option {
        padding: 0 10px;
    }
}

.find-bike-form {
    padding: 54px 57px 58px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.find-bike-form .form-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.find-bike-form .form-wrap label {
    display: block;
    color: #9ca4ab;
}

.find-bike-form .form-wrap>li {
    margin-right: 30px;
    width: calc(33.33% - 15px);
}

.find-bike-form .form-wrap>li:last-child {
    margin-right: 0;
    width: auto;
}

.find-bike-form .form-wrap>li .nice-select {
    width: 100%;
    max-width: 100%;
    border: 1px solid #1b16172e;
    padding: 10px;
    border-radius: 4px;
}

.find-bike-form .form-wrap>li .btn {
    display: block;
    min-width: 195px;
    padding: 9px;
    background-color: #1b1617;
    color: #fff;
}



.main-menu nav ul li>a {
    color: #231f20;
}

.logo>a img {
    width: auto;
}

.stick .logo {
    margin-top: 17px !important;
}

.logo {
    margin-top: 28px;
}

.product-tab-list a h4 {
    color: #1b1617;
    background-color: #fff;
    padding: 10px 24px;
    border-radius: 25px;
    font-size: 14px;
    letter-spacing: 1px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-tab-list a.active h4 {
    color: #fff;
    background-color: #1b1617;
    padding: 10px 24px;
    border-radius: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-content.title-price-wrap-2-white h3 a {
    color: #1b1617;
    font-size: 16px;
    font-weight: 500;
}

.product-content.title-price-wrap-2-white .product-price span {
    color: #1b1617;
}

.header-right-wrap .same-style.header-search .search-content form .button-search{
    background-color: #d4d4d4;
}

.header-right-wrap .same-style.header-search .search-content form .button-search:hover{
    background-color: #d4d4d4;
}

.product-wrap .product-img, .product-list-image-wrap .product-img {
    border-radius: 15px;
}

.shop-bottom-area .product-wrap{
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
}

.nav-style-3.owl-carousel > .owl-nav, .nav-style-3 .ht-swiper-button-nav{
    top: 50%;
    right: 15px;
    font-size: 35px;
    font-weight: 800;
    line-height: 1.5;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid #231f20;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-menu nav ul li ul.submenu{
    margin-top: -20px;
}

.main-menu nav ul li ul.submenu li a{
    font-size: 16px;
}

.footer-logo img {
    width: auto;
}

// .welcome-content h1{
//   font: 700 4em/1 "Oswald", sans-serif;
//   font-style: italic;
//   -webkit-text-stroke: 1.2px #1b1617;
//   letter-spacing: 0;
//   padding: 0.25em 0 0.325em;
//   display: block;
//   margin: 0 auto;
//    background: repeat-y;
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -webkit-animation: aitf 80s linear infinite;
//   -webkit-transform: translate3d(0, 0, 0);
//   -webkit-backface-visibility: hidden;
// }

// @keyframes aitf {
//   0% {
//     background-position: 0% 50%;
//   }
//   100% {
//     background-position: 100% 50%;
//   }
// }

// .welcome-content h1:before {
//     position: absolute;
//     background-color: #070508;
//     height: 3px;
//     width: 70px;
//     content: none;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     margin: 0 auto;
// }






 .slider .buttons {
    z-index: 1;
    right: 50%;
    top: 261px;
    width: 80px;
    height: 40px;
    position: absolute;
    border-radius: 50px;
    transform: translateX(50%);
    background-color: #ffffff;
  }
 .slider .buttons .previous,
   .slider .buttons .next {
    width: 50%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
 .slider .buttons .previous {
    left: 0;
    background-image: url("https://alcs-slider.netlify.app/images/icon-prev.svg");
  }
 .slider .buttons .previous:hover {
    transform: scale(1.25);
  }
 .slider .buttons .next {
    right: 0;
    background-image: url("https://alcs-slider.netlify.app/images/icon-next.svg");
  }
 .slider .buttons .next:hover {
    transform: scale(1.25);
  }
 .slider .slide {
    display: flex;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
 .slider .slide .testimonial {
    padding: 32px 51px;
    background-size: 60px;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(https://alcs-slider.netlify.app/images/pattern-quotes.svg);
  }
 .slider .slide .testimonial blockquote {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 36px;
  }
 .slider .slide .testimonial .author {
    font-size: 15px;
    font-weight: 700;
  }
  .slider .slide .testimonial .author span {
    display: block;
    color: #babacf;
    font-weight: 500;
  }
 .slider .slide .slider-img {
    width: 100%;
    padding: 30px;
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://alcs-slider.netlify.app/images/pattern-bg.svg);
  }
 .slider .slide .slider-img img {
    width: 240px;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 16px 40px 0px rgba(135, 105, 210, 0.4);
  }
 .slider .slide .active {
    opacity: 1;
    transform: translateX(0) scale(1);
    transition-delay: 0.4s;
  }

  html body .hide {
    display: none;
  }
  
  @media screen and (min-width: 600px) {

 .slider .buttons {
      top: 280px;
    }
  }
  @media screen and (min-width: 900px) {

 .slider .buttons {
      right: 43%;
      top: 500px;
    }
 .slider .slide {
      position: relative;
      text-align: left;
      display: flex;
      flex-direction: row;
    }
 .slider .slide .testimonial {
      padding: 32px 0 0 0;
      background-size: 80px;
      background-position: 19% -7%;
      transform: translateX(100px);
    }
.slider .slide .testimonial blockquote {
      font-size: 24px;
      line-height: 30px;
    }
 .slider .slide .testimonial .author span {
      display: contents;
    }
 .slider .slide .slider-img {
      padding: 55px;
    }
 .slider .slide .slider-img img {
      width: 400px;
    }
  }







@media only screen and (min-width: 768px) and (max-width: 991px){
.logo {
    margin-top: 0 !important;
}
}

@media only screen and (max-width: 767px)
{
.logo {
    margin-top: 0 !important;
}}

.logo > a {
    display: block;
    height: 100%;
    text-align: left !important;
}
@media only screen and (min-width: 268px) and (max-width: 990px){
  .stick .logo {
    margin-top: 0px !important;
  }
}


.product-wrap .product-content h2{
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}


.f_size_16 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

footer h5{
  color: rgb(216, 216, 216);
  line-height: 18px;
}

footer a{
  color: rgb(216, 216, 216);
}

footer a:hover{
  color: #444;
    transform: scale(1.1);
}

footer ul.b_cat li, footer ul.com_links li{
  line-height: 18px;
}

footer ul.b_cat li a, footer ul.com_links li a {
    font-size: 14px;
    font-weight: 400;
    color: rgb(216, 216, 216);
    text-transform: uppercase;
}

.f_social_icon a {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: #fff;
    border: 1px solid #e2e2eb;
    font-size: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #444;
    margin-left: 4px;
}

footer hr{
  border: .02em solid rgba(255, 255, 255, 0.11);
}


.contact-info-wrap {
    padding: 50px;
}

.contact-form .contact-title h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
    color: #464646;
}

.contact-info-wrap .contact-title h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #464646;
}

.contact-info-wrap .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.contact-social ul li {
    display: inline-block;
    margin: 10px 20px 0 20px;
}

.contact-info-wrap .single-contact-info:hover .contact-icon i {
    color: #fff;
    background-color: #444;
    border-color: #444;
    border: 1px solid #444;
}

.contact-form .contact-form-style button {
    padding: 15px 35px;
    border: 1px solid #777;
    background-color: transparent;
    color: #777;
    margin-top: 15px;
    font-weight: 400;
}
.contact-form .contact-form-style button:hover{
      background-color: #444;
      color: #fff;
}

.contact-social {
    margin-top: 40px;
}

.contact-social ul li a:hover{
    color: #444
}


.main-menu nav ul {
    text-align: right;
}

.product-wrap .product-content{
    margin: 10px 0;
}

.product-wrap .product-content h3{
    font-weight: 500;
    margin: 10px 0;
}



.description-review-topbar {
    justify-content: left;
    border-bottom: none;
    display: block;
}
.description-review-topbar .nav-item a {
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.description-review-topbar .nav-item a.active {
    border-bottom: none;
    color: #231f20;
    width: 100%;
    line-height: 40px;
    padding-left: 25px;
    text-align: center;
    font-size: 40px;
    font-family: $rc;
    font-weight: 600;
}
.tab-content .active {
    display: block;
    height: auto;
    overflow: visible;
}
.description-review-bottom {
    padding: 0;
}
.description-review-bottom .product-anotherinfo-wrapper ul li span {
    min-width: 150px;
    font-weight: 600;
    color: #555;
}
.description-review-bottom .product-anotherinfo-wrapper ul li {
    margin: 0;
}
.description-review-bottom .product-anotherinfo-wrapper ul li:nth-child(odd) {
    background-color: #e9e9e9;
    padding: 8px 8px 8px 10px;
    color: #555;
}
.description-review-bottom .product-anotherinfo-wrapper ul li:nth-child(2n) {
    background-color: #fff;
    padding: 8px 8px 8px 10px;
    color: #555;
}

.main-menu nav ul{
    text-align: right;
}

.header-area {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.product-details-content .pro-details-meta span{
  font-size: 15px;
  margin-right: 5px;
  color: #5e5b5c;
  font-weight: 600;
}



// amazon button

.amazon-btn{
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  background-image: linear-gradient(#ffd814, #ffa41c);
  font-weight: 500;
  text-transform: uppercase;
  color: #273039;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #273039;
  border-radius: 5px;
}

.amazon-btn i{
  font-size: 20px;
}

.amazon-btn:hover{
  color: #273039;
  transform: scale(1.03);
}
















.main-card {
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.main-card .card-head {
    position: relative;
    height: auto;
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    border-radius: 25px 25px 0 0;
    text-align: center;
}

.main-card .card-logo {
    width: 55px;
    margin: 20px;
    float: left;
}

.main-card .product-img {
    position: relative;
    width: 90%;
    margin: -50px 0 20px 0;
}


.main-card .card-body {
    background: #fff;
    border-radius: 0 0 25px 25px;
    padding: 1.25rem;
}

.main-card .card-body .product-title {
    display: block;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.main-card .card-body .product-title  b {
    font-weight: 900;
    letter-spacing: 0px;
    font-style: italic;
}

.main-card .card-body .product-title .badge {
    position: relative;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    background: #282325;
    padding: 2px 5px;
    border-radius: 4px;
    top: -2px;
    margin-left: 5px;
}

.main-card .card-body .product-caption {
    display: inline;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.main-card .card-body .product-feature-mf,
.main-card .card-body .product-feature-age,
.main-card .card-body .product-feature-height,
.main-card .card-body .product-feature-wheel{
    font-size: 14px;
    letter-spacing: .5px;
    display: inline;
    margin-top: 50px;
    vertical-align: middle;
}


.main-card .card-body .product-feature-mf img,
.main-card .card-body .product-feature-age img,
.main-card .card-body .product-feature-height img,
.main-card .card-body .product-feature-wheel img{
    width: 4%;
}

.main-card .card-body .product-short-description p{
    font-size: 14px;
    margin-top: 14px;
    letter-spacing: .5px;
}

.main-card .card-body .product-feature-frame,
.main-card .card-body .product-feature-suspension,
.main-card .card-body .product-feature-brake,
.main-card .card-body .product-feature-gear{
    display: block;
    font-size: 14px;
    letter-spacing: .5px;
}

.main-card .card-body .product-feature-frame img,
.main-card .card-body .product-feature-suspension img,
.main-card .card-body .product-feature-brake img,
.main-card .card-body .product-feature-gear img{
    width: 10%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    border: 1px solid #dedede;
    margin-top: 5px;
}


.main-card .card-body .product-rating {
    font-size: 11px;
    color: #282325;
    margin-bottom: 10px;
    display: block;
}

.main-card .card-body .product-rating i.grey {
    color: #acacab;
}

.main-card .card-body .product-rate h4 {
    font-size: 11px;
    margin-top: 15px;
    text-transform: uppercase;
}

.main-card .card-body .product-color{
    display: inline;
    position: absolute;
    z-index: 1;
    right: 0;
}

.main-card .card-body .product-color h4 {
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    float: right;
    transform: rotate(90deg);
    font-weight: 600;
    margin-top: -110px;
    margin-right: -35px;
}

.main-card .card-body .ul-color{
    transform: rotate(90deg);
    margin-top: -100px;
    margin-left: -13px;
    display: inline;
    margin-right: -6px;
    position: absolute;
    z-index: 2;
}
.main-card .card-body .ul-color li {
    list-style: none;
    float: left;
    margin-right: 20px;
}

.main-card .card-body .ul-color li a {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.main-card .card-body .ul-color li a.active {
    width: 20px;
    height: 20px;
    border: 2px solid #444;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-1, .card-3{
    transform: scale(.9);
}

.card-2{
    transform: scale(1.05);
}

.shop-bottom-area .product-wrap{
    padding-top: 10px;
    border-radius: 20px;
}

.shop-bottom-area .product-wrap h3 {
    border-top: 2px solid #cfcfcf;
    padding: 10px;
}

.shop-bottom-area .product-wrap h3 a{
    font-size: 22px;
    font-weight: 600;
    font-family: $alg;
    color: #373737;
}

.shop-bottom-area .product-wrap:hover {
    transform: scale(1.05);
    transition: .3s ease-in-out;
}

img {
    max-width: 100%;
}

.product-gallery img {
    height: auto!important;
}

.js-image-zoom__zoomed-image {
    z-index: 1;
}

.product-details-content h2 {
    color: #231f20;
    font-weight: 600;
}

.product-details-content .pro-details-meta {
    padding: 0;
    margin-right: 0;
}

.product-details-content .pro-details-meta span {
    text-transform: uppercase;
    font-size: 18px;
    margin-right: 5px;
    color: #8d8d8d;
    font-weight: 600;
}

.product-details-content .pro-details-meta ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #8d8d8d;
}

.product-details-content .pro-details-size-color {
    display: block;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
    padding: 15px 0 0;
    margin-right: 0;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap span {
    font-size: 15px;
    margin-right: 5px;
    color: #333;
    font-weight: 600;
    letter-spacing: .5px;
}

.pro-details-color-content {
    margin: 0 10px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap span {
    font-size: 15px;
    color: #333;
    font-weight: 600;
    letter-spacing: .5px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark:after {
    left: -3px;
    top: -5px;
    width: 31px;
    height: 31px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    border-radius: 50%;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a{
  font-size: 18px;
  font-family: $rc;
}

.product-details-content .pro-details-list {
    margin: 10px 0 0;
    padding: 0 0 10px;
    border: 0;
}


.description-review-area .product-anotherinfo-wrapper .box-card {
    align-items: center;
}

.description-review-area .product-anotherinfo-wrapper .box-card b{
    font-size: 18px;
    color: #231f20;
}

.description-review-area .product-anotherinfo-wrapper .box-card p{
    color: #231f20;
    font-size: 15px;
}

// .description-review-area .product-anotherinfo-wrapper .box-card img{
//     padding: 20px;
//     background-color: #fff;
//     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//     border-radius: 10%;
// }


// .description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(even){
//     background-color: #fff;
// }

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+5){
    background-color: rgb(241, 241, 241);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+9){
    background-color: rgb(255, 255, 255);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+13){
    background-color: rgb(241, 241, 241);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+17){
    background-color: rgb(255, 255, 255);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+21){
    background-color: rgb(241, 241, 241);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+25){
    background-color: rgb(255, 255, 255);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+29){
    background-color: rgb(241, 241, 241);
}

.description-review-bottom .product-anotherinfo-wrapper .col-lg-3:nth-child(n+33){
    background-color: rgb(255, 255, 255);
}

.product-wrap .product-content a{
    color: #464646;
}

.js-image-zoom__zoomed-image {
    background-color: #fff;
}




.product-details-content
  .pro-details-size-color
  .pro-details-color-wrap
  .pro-details-color-content--single
  .checkmark:after {
  left: -3px;
  top: -6px;
}

.product-details-content .pro-details-size-color .pro-details-size span{
  font-size: 15px;
  margin-right: 5px;
  color: #333;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single {
  // display: inline;
  padding: 0;
  margin: 8px 5px;
  border: 1px solid #e7e7e7;
  background-color: #e7e7e7;
  width: 75px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name {
  font-size: 15px;
  font-weight: 600;
  padding: 12px;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name:hover {
  color: #fff;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
  padding: 15px 0 0px 0;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single:hover {
  background-color: #707070;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.product-details-content .pro-details-size-color {
    display: block;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
    padding: 15px 0 0;
    margin-right: 0;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark:after {
    left: -3px;
    top: -5px;
    width: 31px;
    height: 31px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
}

.product-details-content .pro-details-size{
      padding: 15px 0;
    margin-right: 0;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap span{
    font-size: 15px;
    margin-right: 5px;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name{
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35); 
    color: #fff;
    background-color: #e7e7e7;
    color: #5e5b5c;
    border: 1px solid #000;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:hover {
    color: #fff;
    background-color: #e7e7e7;
    border: 1px solid #000;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name:hover {
  color: #fff;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover{
    background-color: #e7e7e7;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #5e5b5c;
    border: 1px solid #000;
}

.buyNow:hover{
  transform: scale(1.05);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.buy-online-label{
    font-size: 15px;
    margin-right: 5px;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.5px;
}