.slick-slide img{
    display: inline;
}

.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 7.3em;
  transition: 0.4s;
}

.next.slick-arrow {
  right: -14px;
}

.prev.slick-arrow {
  left: -14px;
}

.featured-slider .slick-slider .slick-list {
    height: 800px;
    padding-top: 50px!important;
}

.featured-slider .product-item {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 20px;
    transform: scale(0.9);
    text-align: left;
}

.featured-slider .product-item {
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.featured-slider .slick-current .product-item{
    transform: scale(1.1);
}

.featured-slider .product-item .product-title {
    display: block;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.featured-slider .product-item .product-title  b {
    font-weight: 900;
    letter-spacing: 0px;
    font-style: italic;
}

.featured-slider .product-item .product-title .badge {
    position: relative;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    background: #282325;
    padding: 2px 5px;
    border-radius: 4px;
    top: -2px;
    margin-left: 5px;
}

.featured-slider .product-item .product-caption {
    display: inline;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.featured-slider .product-item .product-feature-mf,
.featured-slider .product-item .product-feature-age,
.featured-slider .product-item .product-feature-height,
.featured-slider .product-item .product-feature-wheel{
    font-size: 14px;
    letter-spacing: .5px;
    display: inline;
    margin-top: 50px;
    vertical-align: middle;
}

.featured-slider .product-item .product-feature-mf img,
.featured-slider .product-item .product-feature-age img,
.featured-slider .product-item .product-feature-height img,
.featured-slider .product-item .product-feature-wheel img{
    width: 4%;
}

.featured-slider .product-item .product-short-description p{
    font-size: 14px;
    margin-top: 14px;
    letter-spacing: .5px;
}

.featured-slider .product-item .product-feature-frame,
.featured-slider .product-item .product-feature-suspension,
.featured-slider .product-item .product-feature-brake,
.featured-slider .product-item .product-feature-gear{
    display: flex;
    vertical-align: middle;
    align-items: center;
    font-size: 14px;
    letter-spacing: .5px;
}

.featured-slider .product-item .product-feature-frame img,
.featured-slider .product-item .product-feature-suspension img,
.featured-slider .product-item .product-feature-brake img,
.featured-slider .product-item .product-feature-gear img{
    width: 12%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    border: 1px solid #dedede;
    margin-top: 5px;
    margin-right: 5px;
    padding: 5px;
}

.featured-slider .product-item .product-feature-frame span,
.featured-slider .product-item .product-feature-suspension span,
.featured-slider .product-item .product-feature-brake span,
.featured-slider .product-item .product-feature-gear span{
    margin-top: 5px;
    line-height: 1.3;
}

.featured-slider .product-item .product-rating {
    font-size: 11px;
    color: #282325;
    margin-bottom: 10px;
    display: block;
}

.featured-slider .product-item .product-rating i.grey {
    color: #acacab;
}

.featured-slider .product-item .product-rate h4 {
    font-size: 11px;
    margin-top: 15px;
    text-transform: uppercase;
}

.featured-slider .product-item .product-color span{
    font-size: 26px;
    margin: 0px 5px 0px 5px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 2px solid #fff;
    height: 29px;
    width: 29px;
}

.featured-slider .product-item .product-color span:hover{
    border: 2px solid #000;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 280px) and (max-width: 500px){
    .featured-slider .slick-slider .slick-list{
        height: 700px !important;
    }
}