@media only screen and (min-width: 280px) and (max-width: 400px){
.slider-height-5{
    height: 24vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 401px) and (max-width: 500px){
.slider-height-5{
    height: 30vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 501px) and (max-width: 650px){
.slider-height-5{
    height: 40vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 651px) and (max-width: 700px){
.slider-height-5{
    height: 45vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 701px) and (max-width: 750px){
.slider-height-5{
    height: 50vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 751px) and (max-width: 800px){
.slider-height-5{
    height: 55vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 801px) and (max-width: 900px){
.slider-height-5{
    height: 60vh !important;
}
.nav-style-3 .ht-swiper-button-nav.swiper-button-prev,
.nav-style-3 .ht-swiper-button-nav.swiper-button-next{
    display: none;
}
}
@media only screen and (min-width: 901px) and (max-width: 1020px){
.slider-height-5{
    height: 70vh !important;
}
}
@media only screen and (min-width: 1021px) and (max-width: 1140px){
.slider-height-5{
    height: 78vh !important;
}
}
@media only screen and (min-width: 1141px) and (max-width: 1350px){
.slider-height-5{
    height: 80vh !important;
}   
}
@media only screen and (min-width: 1351px) and (max-width: 1450px){
.slider-height-5{
    height: 80vh !important;
}
}
@media only screen and (min-width: 1451px) and (max-width: 1600px){
.slider-height-5{
    height: 80vh !important;
}
}
@media only screen and (min-width: 1601px) and (max-width: 1700px){
.slider-height-5{
    height: 83vh !important;
}
}